<template>
  <aside class="sidebar gradient">
    <CloseMenuSVG
      @click="$store.state.isToggle = !$store.state.isToggle"
      class="menu"
    />
    <div class="profile">
      <div class="avatar">
        <img :src="require('@/assets/images/sample.png')" alt="icon" />
      </div>
      <div class="details">
        <span class="name">{{ $store.state.adminLogDetails.name }}</span>
        <span class="position">Chief Executive Officer</span>
      </div>
    </div>
    <nav>
      <ul>
        <li v-for="(item, index) in itemCategories" :key="index">
          <div
            :class="{ active: $route.meta.main === item.name }"
            @click="$store.state.isToggle = !$store.state.isToggle"
          >
            <router-link :to="item.path" class="nav-menu">
              <component :is="item.icon" class="icon"></component>
              {{ item.name }}
            </router-link>
          </div>
        </li>
      </ul>
    </nav>
  </aside>
</template>
<script>
import CloseMenuSVG from "@/components/svgs/CloseMenuIcon.vue";
import DashboardSVG from "@/components/svgs/DashboardIcon.vue";
import ReportSVG from "@/components/svgs/ReportIcon.vue";
import { reactive } from "vue";
export default {
  components: {
    DashboardSVG,
    ReportSVG,
    CloseMenuSVG,
  },
  setup() {
    const itemCategories = reactive([
      {
        name: "Dashboard",
        icon: "DashboardSVG",
        path: "/dashboard",
      },
      {
        name: "Reports",
        icon: "ReportSVG",
        path: "/reports",
      },
    ]);

    return {
      itemCategories,
    };
  },
};
</script>

<style scoped>
.sidebar {
  flex-shrink: 0;
  min-width: 310px;
  display: flex;
  flex-direction: column;
}

.gradient {
  background: linear-gradient(
    170deg,
    var(--primary-gradient) -25%,
    var(--secondary-gradient) 40%
  );
}
.profile {
  margin: 50px 20px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.profile .avatar img {
  height: 65px;
  width: 70px;
}
.profile .details {
  display: flex;
  flex-direction: column;
}
.profile .details .name {
  color: var(--light-color);
  font-size: 18px;
  font-weight: 600;
}
.profile .details .position {
  color: var(--font-color-two);
  font-size: 15px;
  font-weight: 600;
}
.menu {
  display: none;
}
nav {
  margin: 0px 20px;
}
nav ul {
  list-style: none;
  padding: 0;
}
nav ul li {
  margin: 10px 0;
  border-radius: 8px;
}
.active > a {
  color: var(--light-color);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background: rgb(255, 255, 255, 0.25);
}
nav ul li:hover {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background: rgb(255, 255, 255, 0.12);
}
nav ul li a {
  text-decoration: none;
  color: var(--semi-light-color);
  font-size: 1em;
  transition: color 0.3s ease;
  padding: 12px 22px;
}
nav ul li a:hover {
  color: var(--light-color);
}
.nav-menu {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media only screen and (min-width: 320px) and (max-width: 440px) {
  .sidebar {
    min-width: 100%;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
  }

  .sidebar.activeToggle {
    transform: translateX(0); /* Slide in */
    opacity: 1; /* Fade in */
  }
  .profile {
    margin: -5px 0px 50px 0px;
    display: flex;
    justify-content: center; /* Center the profile section */
    align-items: center;
    flex-direction: column; /* Stack avatar and details vertically */
  }

  .profile .avatar {
    margin-bottom: -10px; /* Space between avatar and details */
  }

  .profile .avatar img {
    height: 70px;
    width: 70px;
  }

  .profile .details {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the text */
    text-align: center; /* Ensure the text is centered */
  }

  .profile .details .name {
    font-size: 14px; /* Adjust font size for mobile */
    font-weight: 600;
    color: var(--light-color);
  }

  .profile .details .position {
    font-size: 12px; /* Adjust font size for mobile */
    color: var(--font-color-two);
    font-weight: 500;
  }
  nav {
    margin: -30px 20px;
  }
  .menu {
    top: 0;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
  }
}
</style>
