<template>
  <NoDataTable v-if="!dataResult.length" />
  <div v-else>
    <div class="header-title">
      <h3>{{ dataDetails.companyname }}</h3>
    </div>
    <ul>
      <li v-for="(item, index) in dataResult" :key="index">
        <router-link
          :to="{
            path: `/reports/company/plans/details/${item.PlanID}`,
            query: { item: encryptData(item) },
          }"
          class="card"
        >
          <h3>{{ item.Premium }}</h3>
          <h4>{{ item["Risk Classification"] }}</h4>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import UsedFormatUtils from "@/utils/format";
import { ref } from "vue";
export default {
  components: {},
  props: {
    dataResult: Object,
    dataDetails: Object,
    searchData: String,
  },
  setup() {
    const { convertDateReportFormat } = UsedFormatUtils();
    const isShowCategory = ref(true);
    const itemPlanInfo = ref();

    const encryptData = (data) => {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.VUE_APP_SECRET_KEY
      ).toString();
    };
    const getBenefitDetails = (item) => {
      console.log(item);
      itemPlanInfo.value = item;
      isShowCategory.value = false;
    };
    return {
      convertDateReportFormat,
      itemPlanInfo,
      isShowCategory,
      getBenefitDetails,
      encryptData,
    };
  },
};
</script>
<style scoped>
.header-title {
  margin-top: 18px;
}
.header-title h3 {
  color: #f37420;
}
.header-title h4 {
  color: #4e4e4e;
  font-weight: 700;
}
.main-table {
  max-width: 1550px;
  overflow: auto;
}
.col-span-1 {
  min-width: 140px;
}
.col-span-2 {
  min-width: 180px;
}
.col-span-3 {
  min-width: 200px;
}
.col-span-4 {
  min-width: 250px;
}
.col-span-5 {
  min-width: 300px;
}
.col-span-max {
  min-width: 450px;
}
td {
  text-align: left;
}
ul {
  margin: 30px 0px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 14px;
}
ul > li .card {
  height: 190px;
  background-color: var(--light-color);
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: var(--font-color-four);
}
@media only screen and (min-width: 320px) and (max-width: 440px) {
  ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 14px;
  }
}
</style>
